import Router from './route/Router';
import './App.css';


function App() {
  return (
    <Router />
  );
}

export default App;